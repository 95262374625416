<template>
    <div>
        <div class="upload_video">
            <div class="title_text">
                <span>*</span> 训练视频
            </div>
            <div style="display: flex;position: relative;" v-loading='loading'>
                <video v-if="trainVideo" controls="controls" class="video_cover mr20" :poster="coverUrl" :src="trainVideo"
                    controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                <el-upload v-else class="upload_video" action="#" accept="video/mp4,video/mov" :show-file-list="false"
                    :before-upload="beforeUpload" :http-request="uploadBtn">
                    <div class="upload_img">
                        <img src="@/assets/images/workben/upload.png" alt="">
                    </div>
                    <div class="upload_text">
                        上传视频
                    </div>
                </el-upload>
                <div class="absolute">
                    <el-upload action="#" accept="video/mp4,video/mov" v-if="trainVideo" :show-file-list="false"
                        :before-upload="beforeUpload" :http-request="uploadBtn">
                        <div class="re_upload"><img src="@/assets/images/workben/upload.png" alt=""> 重新上传</div>
                    </el-upload>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            trainVideo: '',
            coverUrl: '',
            loading: false,
        };
    },
    created() {

    },
    methods: {
        uploadBtn(item) {
            var that = this
            that.loading = true
            let newFile = new FormData()
            newFile.append('file', item.file)
            that.$publicApi.uploadVideo(newFile).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.trainVideo = res.result.url
                    that.$emit('successVideo', res.result.short_url)
                } else {
                    that.$message.error(res.message);
                }
            })
        },
        //
        videoUrl(val) {
            if (val.indexOf("mp4") != -1 || val.indexOf("mov") != -1) {
                this.trainVideo = val
            } else {
                this.trainVideo = ''
            }
        },
        videoSize(file) {
            var that = this
            return new Promise((resolve, reject) => {
                const _URL = window.URL || window.webkitURL
                const videoElement = document.createElement('video')
                videoElement.addEventListener('loadedmetadata', function (_event) {
                    const duration = Math.round(videoElement.duration) //时长
                    file.duration = duration
                    file.width = videoElement.videoWidth
                    file.height = videoElement.videoHeight
                    const fileSize = (file.width / file.height)
                    if (file.duration <= 20 || file.duration >= 300) {
                        that.$message.error("训练视频仅支持时长大于1min,小于5min");
                        return false
                    }
                    if (fileSize > 1.90 && fileSize < 1.92) {
                        file.scale = '1.91:1'
                    } else if (fileSize < 1.78 && fileSize > 1.76) {
                        file.scale = '16:9'
                    } else if (fileSize < 0.68 && fileSize > 0.66) {
                        file.scale = '1:1.48'
                    } else if (fileSize < 0.57 && fileSize > 0.55) {
                        file.scale = '9:16'
                    } else {
                        file.scale == ''
                    }
                    if (file.scale == '16:9' || file.scale == '9:16') {
                        // console.log('通过')
                        resolve()
                    } else {
                        that.$message.error("视频尺寸只支持9:16、16:9");
                        return false
                    }
                })
                videoElement.src = _URL.createObjectURL(file)
            }).then(
                () => {
                    return file
                },
                () => {
                    this.$message.error(file.name + '上传失败')
                    return Promise.reject()
                }
            )
        },
        beforeUpload(file) {
            const imgType = file.type === 'video/mp4' || file.type === 'video/mov'
            const isLt500M = file.size / 1024 / 1024 < 500;
            if (!imgType) {
                this.$message.error("上传视频只能是 mp4和mov 格式!");
                return false;
            }
            if (!isLt500M) {
                this.$message.error("上传视频大小不能超过 500M!");
                return false;
            }
            return this.videoSize(file)
        },

    },
};
</script>

<style scoped lang="scss">
.upload_video {
    display: flex;
    position: relative;

    .title_text {
        width: 78px;
        font-size: 14px;
        text-align: right;
        color: #333333;
        margin-right: 10px;
        line-height: 40px;

        span {
            color: #e00c25;
        }
    }

    ::v-deep .el-upload {
        width: 100%;
        height: 100%;
    }

    .upload_video {
        width: 708px;
        height: 410px;
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        margin-right: 40px;
        cursor: pointer;
        position: relative;

        .upload_img {
            width: 24px;
            height: 24px;
            margin: 170px auto 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .upload_text {
            font-size: 14px;
            color: #2e4bf2;
            line-height: 20px;
            text-align: center;
            padding-top: 6px;
        }
    }

    .video_cover {
        width: 708px;
        height: 410px;
        border-radius: 4px;
        -o-object-fit: contain;
        object-fit: contain;
        background: #1a1a1a;
    }

    .absolute {
        position: absolute;
        bottom: 0;
        right: -60px;
        width: 90px;
        font-size: 14px;
        color: #2e4bf2;
        line-height: 20px;
        z-index: 100;

        .empower {
            width: 90px;
            text-decoration: underline;
            cursor: pointer;
        }

        .re_upload {
            width: 90px;
            text-decoration: underline;
            display: flex;
            margin-top: 18px;

            img {
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 4px;
            }
        }
    }
}

.mr20 {
    margin-right: 40px;
}
</style>
