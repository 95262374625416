<template>
    <div>
        <div class="upload_video">
            <div class="title_text">
                <span>*</span> 形象授权
            </div>
            <div style="display: flex;position: relative;" v-loading='loading'>
                <div class="img mr40" v-if="type == 'image'">
                    <img :src="grantUrl" alt="">
                </div>
                <video v-else-if="type == 'video' && grantUrl" controls="controls" class="video_cover mr40"
                    :poster="coverUrl" :src="grantUrl" controlslist="nodownload noremoteplayback noplaybackrate"
                    disablepictureinpicture></video>
                <el-upload v-else class="upload_video" action="#"
                    accept="image/jpg,image/jpeg,image/png,video/mp4,video/mov" :show-file-list="false"
                    :before-upload="beforeUpload" :http-request="uploadBtn">
                    <div class="upload_img">
                        <img src="@/assets/images/workben/upload.png" alt="">
                    </div>
                    <div class="upload_text">
                        上传授权视频或授权书图片
                    </div>
                </el-upload>
                <div class="absolute">
                    <div class="empower" @click="downloadEmpower">下载授权书</div>
                    <el-upload v-if="grantUrl" action="#" accept="image/jpg,image/jpeg,image/png,video/mp4,video/mov"
                        :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadBtn">
                        <div class="re_upload"><img src="@/assets/images/workben/upload.png" alt=""> 重新上传</div>
                    </el-upload>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            headers: {
                'Authorization': this.$store.state.token
            },
            grantUrl: '',
            coverUrl: '',
            type: '',
            fileType: '',
            //
            loading: false,
        };
    },
    created() {

    },
    methods: {
        //
        grantUrlInfo(val) {
            if (val.indexOf("mp4") != -1 || val.indexOf("mov") != -1) {
                this.type = 'video'
                this.grantUrl = val
            } else if (val.indexOf("jpg") != -1 || val.indexOf("jpeg") != -1 || val.indexOf("png") != -1) {
                this.type = 'image'
                this.grantUrl = val
            } else {
                this.grantUrl = ''
            }
        },
        uploadBtn(item) {
            var that = this
            that.loading = true
            let newFile = new FormData()
            newFile.append('file', item.file)
            that.$publicApi.uploadGrantFile(newFile).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.grantUrl = res.result.url
                    that.type = that.fileType
                    that.$emit('successGrant', res.result.short_url)
                } else {
                    that.$message.error(res.message);
                }
            })
        },
        beforeUpload(file) {
            if (file.type == 'video/mp4' || file.type == 'video/mov') {
                this.fileType = 'video'
            } else {
                this.fileType = 'image'
            }
            const isLt500M = file.size / 1024 / 1024 < 500;
            if (!isLt500M) {
                this.$message.error("上传文件大小不能超过 500M!");
                return false;
            }
        },
        // 下载授权书
        downloadEmpower() {
            window.open('https://yc-digital.oss-cn-shenzhen.aliyuncs.com/file/letter_of_authority.pdf', '_blank')
        },

    },
};
</script>

<style scoped lang="scss">
.upload_video {
    display: flex;
    position: relative;

    .title_text {
        width: 78px;
        font-size: 14px;
        text-align: right;
        color: #333333;
        margin-right: 10px;
        line-height: 40px;

        span {
            color: #e00c25;
        }
    }

    ::v-deep .el-upload {
        width: 100%;
        height: 100%;
    }

    .upload_video {
        width: 708px;
        height: 410px;
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        margin-right: 40px;
        cursor: pointer;
        position: relative;

        .upload_img {
            width: 24px;
            height: 24px;
            margin: 170px auto 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .upload_text {
            font-size: 14px;
            color: #2e4bf2;
            line-height: 20px;
            text-align: center;
            padding-top: 6px;
        }
    }

    .video_cover {
        width: 708px;
        height: 410px;
        border-radius: 4px;
        -o-object-fit: contain;
        object-fit: contain;
        background: #1a1a1a;
    }

    .img {
        width: 708px;
        height: 410px;
        border-radius: 4px;
        background: #1a1a1a;

        img {
            width: auto;
            height: 100%;
            margin: 0 auto;
        }
    }

    .absolute {
        position: absolute;
        bottom: 0;
        right: -60px;
        width: 90px;
        font-size: 14px;
        color: #2e4bf2;
        line-height: 20px;
        z-index: 100;

        .empower {
            width: 90px;
            text-decoration: underline;
            cursor: pointer;
        }

        .re_upload {
            width: 90px;
            text-decoration: underline;
            display: flex;
            margin-top: 18px;

            img {
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 4px;
            }
        }
    }
}

.mr40 {
    margin-right: 40px;
}
</style>
